
import React from 'react';
import { SocialList } from '../Social/styles';

const Social = () => {
    return (
        <SocialList>
            <li className='hide--belowDesktopOnly btn-guide'>
                <a href='/augmentation-room'>Augmentation Room</a>
            </li>
            <li>
                <a href='https://twitter.com/Mohawk_nfts' rel="noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.99 57"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M47.29,7.12A27.31,27.31,0,0,0,33.12.39,27.67,27.67,0,0,0,12.57,4.91a27.94,27.94,0,0,0-12,17.7C.37,23.78.27,25,.1,26.18c0,.18-.07.36-.1.54v3.56c.14,1.06.25,2.13.44,3.19A28.09,28.09,0,0,0,9.66,49.85a28.18,28.18,0,0,0,15,6.89c.69.1,1.39.17,2.09.26h3.56c1-.15,2.09-.25,3.13-.44A28.16,28.16,0,0,0,53,43a27.06,27.06,0,0,0,4-15.11A28,28,0,0,0,47.29,7.12ZM43.34,20.05a18.39,18.39,0,0,1-1.71,1.59,1.08,1.08,0,0,0-.43.93c-.2,7.69-3.46,13.64-10.43,17.06-5.95,2.92-12,2.49-17.78-.79-.09-.05-.17-.12-.39-.27a13,13,0,0,0,6.6-1A22.1,22.1,0,0,0,22.1,36a7,7,0,0,1-6-4.67h2.7l0-.13a6.69,6.69,0,0,1-5-6.38l2.76.77.09-.15A6.62,6.62,0,0,1,14,21.45a6.49,6.49,0,0,1,.71-4.72,18.73,18.73,0,0,0,13.41,6.83,18.23,18.23,0,0,1,0-2.22,6.54,6.54,0,0,1,10.94-4.07.74.74,0,0,0,.83.21l3.6-1.28a6.53,6.53,0,0,1-2.61,3.3,1,1,0,0,0,.07.15l3.42-.92C44,19.22,43.71,19.66,43.34,20.05Z"/></g></g></svg>
                </a>
            </li>
            <li>
                <a href='https://www.instagram.com/mohawk_nfts/' rel="noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.99 57"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M28.5,23.16a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Zm0,0a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Zm0,0a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Zm0,0a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Zm13.3-3.49a5.31,5.31,0,0,0-5-4.53c-5.46,0-10.93,0-16.39,0a5.51,5.51,0,0,0-.71.06,5.31,5.31,0,0,0-4.51,5c0,2.74,0,5.49,0,8.24s0,5.24,0,7.86a7.89,7.89,0,0,0,.06,1,5.3,5.3,0,0,0,5.08,4.55c5.44,0,10.88,0,16.32,0a5.83,5.83,0,0,0,.72-.06,5.29,5.29,0,0,0,4.52-5.06c0-5.46,0-10.91,0-16.36A5.36,5.36,0,0,0,41.8,19.67ZM28.53,36.51a8,8,0,1,1,8-8A8,8,0,0,1,28.53,36.51ZM37.2,21.8a2,2,0,0,1,0-4,2,2,0,0,1,2,2A2,2,0,0,1,37.2,21.8Zm-8.7,1.36a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Zm0,0a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Zm0,0a5.33,5.33,0,1,0,5.34,5.32A5.32,5.32,0,0,0,28.5,23.16Z"/><path d="M56.55,23.59A28.08,28.08,0,0,0,47.4,7.21,27.34,27.34,0,0,0,33.05.37,27.72,27.72,0,0,0,10.82,6.19,27.69,27.69,0,0,0,.2,25.19,28.51,28.51,0,0,0,22.71,56.4c1.16.24,2.34.33,3.52.5a4.1,4.1,0,0,1,.48.1h3.56c1-.15,2.09-.24,3.13-.44A28.16,28.16,0,0,0,53,43a27.05,27.05,0,0,0,3.89-12.27,2.07,2.07,0,0,1,.1-.43V26.72C56.84,25.67,56.73,24.62,56.55,23.59Zm-12,13.74-.06.23a13.29,13.29,0,0,1-.61,2.12,7.82,7.82,0,0,1-7.5,4.85c-5.24,0-10.48,0-15.73,0a7.87,7.87,0,0,1-3.82-.89,7.62,7.62,0,0,1-4.34-6.81c-.1-5.56,0-11.13,0-16.7A6.52,6.52,0,0,1,12.86,18a7.88,7.88,0,0,1,5.93-5.37c.29-.08.58-.13.88-.19H37.34l.21.07a11.23,11.23,0,0,1,1.42.32,8,8,0,0,1,5.39,5.88c.08.31.13.63.19.94Z"/></g></g></svg>
                </a>
            </li>
            <li>
                <a href='#' rel="noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 56.99"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M56.55,23.49a28.12,28.12,0,0,0-11.3-18,26.77,26.77,0,0,0-12-5A27.85,27.85,0,0,0,11,6,27.43,27.43,0,0,0,.53,23.13,27.7,27.7,0,0,0,6,46,27.78,27.78,0,0,0,22.57,56.38c1.13.25,2.3.34,3.45.51.17,0,.32.07.49.1h4c1-.14,2-.26,3-.44a27.77,27.77,0,0,0,14.31-7.11,28.22,28.22,0,0,0,8.8-16.25c.15-.89.24-1.79.37-2.68v-4C56.85,25.5,56.74,24.49,56.55,23.49ZM49,38.21a1.41,1.41,0,0,1-.67,1.18A36,36,0,0,1,39,44.06c-.36.12-.57.09-.77-.25-.63-1-1.29-2.07-2-3.13L39.7,39c-.51-.76-.69-.82-1.4-.51a24.26,24.26,0,0,1-19.52,0c-.8-.34-.84-.33-1.52.45l3.5,1.71c-.73,1.14-1.43,2.25-2.16,3.36-.05.07-.27.08-.38,0a33.07,33.07,0,0,1-9.81-5,1.08,1.08,0,0,1-.34-.75c0-2-.14-4,0-6a34.15,34.15,0,0,1,5.85-16.5,1.88,1.88,0,0,1,.77-.65,32.53,32.53,0,0,1,7.6-2.36.47.47,0,0,1,.6.3c.24.52.48,1,.77,1.54a.62.62,0,0,0,.49.26,31.69,31.69,0,0,1,8.7,0,.63.63,0,0,0,.5-.26c.28-.49.51-1,.75-1.54a.45.45,0,0,1,.59-.3,33.65,33.65,0,0,1,7.77,2.41,1.23,1.23,0,0,1,.49.41,34.84,34.84,0,0,1,5.78,14.69A36.54,36.54,0,0,1,49,38.21Z"/><path d="M25.43,30a4.48,4.48,0,0,1-1.18,3.05,3.4,3.4,0,0,1-5.07.06,4.5,4.5,0,0,1-.06-6.06,3.29,3.29,0,0,1,3.38-1,3.74,3.74,0,0,1,2.76,2.9A8,8,0,0,1,25.43,30Z"/><path d="M39.09,29.92a4.62,4.62,0,0,1-1,3,3.44,3.44,0,0,1-5.55-.17A4.37,4.37,0,0,1,32.14,28,3.56,3.56,0,0,1,35.92,26a3.89,3.89,0,0,1,3,3C39,29.34,39.06,29.71,39.09,29.92Z"/></g></g></svg>
                </a>
            </li>
            <li>
                <a href='https://sg.linkedin.com/in/b2bsocialmarketing' rel="noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM14.41,41.55c0-.12,0-.25,0-.37v-19h6.06V41.18c0,.12,0,.24,0,.37Zm6.53-25.24a3.5,3.5,0,0,1-6.93.32.58.58,0,0,0,0-.14V15.41a10.28,10.28,0,0,1,.34-1,3.5,3.5,0,0,1,4.12-1.85A3.53,3.53,0,0,1,20.94,16.31ZM37,41.55c0-3.1,0-6.19,0-9.29a21.79,21.79,0,0,0-.17-2.68A2.68,2.68,0,0,0,34.2,27a3.11,3.11,0,0,0-3.4,1.76,6,6,0,0,0-.41,1.55,19.07,19.07,0,0,0-.11,2.27c0,3,0,6,0,9h-6V22.13H30v2.68a2.19,2.19,0,0,0,.16-.2,6,6,0,0,1,4.41-2.88,8.62,8.62,0,0,1,4.71.51A5.52,5.52,0,0,1,42.48,26,14,14,0,0,1,43,30.07c0,3.83,0,7.65,0,11.48Z"/></g></g></svg>
                </a>
            </li>
        </SocialList>
    );
};

export default Social;
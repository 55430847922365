import React, { useState } from 'react';
import { MenuContainer, MenuList } from './styles';
import Social from '../Social/index';

const Menu = ({isMenuOpened, closeMenu}) => {

    const MENU_LIST = [
        {
            text: "Collection",
            active: true,
        },
        {
            text: "Roadmap",
            active: false,
        },
        {
            active: false,
            text: "Team",
        },
        {
            active: false,
            text: "FAQ",
        },
    ];

    const [menuList] = useState(MENU_LIST);

    const updateMenu = (e) =>{
        document.querySelector('.menu-list li.active').classList.remove("active");
        setTimeout(() => {
            e.target.parentElement.classList.add('active');
        },100);
        
        closeMenu();
    };

    return (
        <MenuContainer className={`${isMenuOpened ? 'is-open' : ''}`}>
            <MenuList className="menu-list">
                {
                    menuList.map((menu) => {
                        return (
                            <li key={menu.text} onClick={(e) => updateMenu(e)} className={`${menu.active ? 'active' : ''}`}>
                                <a className='' href={`/#${menu.text.toLowerCase().replace(/\s/g, '')}`}>{menu.text}</a>
                            </li>
                        );
                    })
                }
                <li className='hide--desktop'>
                    <a href='/augmentation-room'>Augmentation Room</a>
                </li>
            </MenuList>
            <Social />
        </MenuContainer>
    );
};

export default Menu;
import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const AugmentationContainer = styled.section`
    position: relative;
    width: 100%;
    text-align: center;
    background: url('/images/bg-benefit.png') no-repeat;
    background-position-x: center;

    @media ${breakpoints.lg} {
        background-position-y: center;
        background-size: 165%;
    }

    @media ${breakpoints.md} {
        background-position-y: center;
        background-size: 165%;
    }

    @media ${breakpoints.xs} {
        background-position-y: -25%;
        background-size: 200%;
    }

    h2 {
        font-size: 158px;
        padding: 30px 0 423px;
        text-transform: uppercase;

        @media ${breakpoints.lg} {
            padding: 30px 0 43vw;
            font-size: 10vw;
        }

        @media ${breakpoints.md} {
            padding: 0 0 44vw;
            font-size: 10vw;
        }

        @media ${breakpoints.xs} {
            padding: 0 0 48vw;
            font-size: 46px;
        }
    }

    p {
        width: 780px;
        font-size: 30px;
        padding: 0 0 70px;
        margin: 0;

        @media ${breakpoints.lg} {
            width: 900px;
        }

        @media ${breakpoints.md} {
            font-size: 18px;
            width: 500px;
        }

        @media ${breakpoints.xs} {
            font-size: 16px;
            width: 90%;
            padding: 0 0 34px;
        }
    }
`;

export const CloudOne = styled.div`
    background: url(/images/cloud-1.png) no-repeat;
    background-position-y: top;
    background-size: 100%;
    width: 1042px;
    height: 614px;
    position: absolute;
    left: 50%;
    top: -10%;
    transform: translateX(-8%);
    animation: cloudOneAnimation 20s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 45vw;
        top: -10%;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 30vh;
        top: -10%;
    }

    @media ${breakpoints.xs} {
        width: 47vw;
        top: 3%;
    }

    @keyframes cloudOneAnimation {
        0% {
            transform: translateX(-31%);
        }

        50% {
            transform: translateX(-8%);
        }
        
        100% {
            transform: translateX(-31%);
        }
    }
`;

export const CloudTwo = styled.div`
    background: url(/images/cloud-2.png) no-repeat;
    background-position-y: top;
    background-size: 100%;
    width: 968px;
    height: 394px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-111%);
    animation: cloudTwoAnimation 30s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 31vh;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 22vh;
    }

    @media ${breakpoints.xs} {
        width: 66vw;
        height: 18vh;
        top: 20%;
    }

    @keyframes cloudTwoAnimation {
        0% {
            transform: translateX(-111%);
        }

        50% {
            transform: translateX(-90%);
        }
        
        100% {
            transform: translateX(-111%);
        }
    }
`;

export const CloudThree = styled.div`
    background: url(/images/cloud-3.png) no-repeat;
    background-position-y: top;
    background-size: 80%;
    width: 968px;
    height: 394px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-2%);
    animation: cloudThreeAnimation 15s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 31vh;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 22vh;
    }

    @media ${breakpoints.xs} {
        width: 54vw;
        top: 53%;
    }

    @keyframes cloudThreeAnimation {
        0% {
            transform: translateX(-2%);
        }

        50% {
            transform: translateX(-14%);
        }
        
        100% {
            transform: translateX(-2%);
        }
    }
`;

export const CloudFour = styled.div`
    background: url(/images/cloud-4.png) no-repeat;
    background-position-y: top;
    background-size: 80%;
    width: 1072px;
    height: 502px;
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translateX(-68%%);
    animation: cloudFourAnimation 25s ease-in-out infinite;

    @media only screen and (min-width: 992px) and (max-width: 1399px) {
        width: 46vw;
        height: 31vh;
    }

    @media ${breakpoints.belowDesktopOnly} {
        width: 36vw;
        height: 22vh;
    }

    @media ${breakpoints.xs} {
        width: 56vw;
        height: 14vh;
        bottom: 15%;
    }

    @keyframes cloudFourAnimation {
        0% {
            transform: translateX(-38%);
        }

        50% {
            transform: translateX(-68%);
        }
        
        100% {
            transform: translateX(-38%);
        }
    }
`;

export const CloudFloat = styled.div`
    animation: float 6s ease-in-out infinite;

    @keyframes float {
        0% {
            shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            // transform: translateY(0%);
        }
        50% {
            shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            // transform: translateY(-0.5%);
        }
        100% {
            shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            // transform: translateY(0%);
        }
    }
`;

export const AugmentationContent = styled.div`
    padding: 335px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;

    @media ${breakpoints.belowDesktopOnly} {
        padding: 80px 0 0;
    }

    @media ${breakpoints.md} {
        max-width: 50%;
    }

    @media ${breakpoints.xs} {
        padding: 25px 34px 0;
    }

    .logo {
        @media ${breakpoints.lg} {
            width: 28vw;
        }

        @media ${breakpoints.md} {
            width: 28vw;
        }

        @media ${breakpoints.xsmd} {
            width: 28vw;
            margin-bottom: 25px;
        }
    }
`;


export const Date = styled.div`

`;

export const Price = styled.div`
    width: 152px;
    text-align: center;
    background: ${color.beige};
    color: ${color.bgColor};
    padding: 8px 0;

    @media ${breakpoints.xsmd} {
        width: 75px;
    }
`;

export const Separator = styled.div`
    margin-top: 140px;
    background: ${color.beige};

    @media ${breakpoints.md} {
        margin-top: 50px;
    }

    @media ${breakpoints.xs} {
        margin-top: 46px;
    }

    img {
        width: 100%;
        max-width: 2613px;
    }
`;

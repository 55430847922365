import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';
import  { SocialList } from '../Social/styles';

export const MenuContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    padding: 90px 150px 0;
    display: none;

    @media ${breakpoints.xs} {
        padding: 100px 38px 90px;
    }

    @media ${breakpoints.md} {
        padding: 150px 60px 90px;
    }

    &.is-open {
        display: block;
        top: 0;
        background: ${color.bgColor};
    }

    ${SocialList} {
        display: none !important;
        
        @media ${breakpoints.belowDesktopOnly} {
            display: flex !important;
            align-items: center;
            justify-content: flex-start;
            margin-top: 50px;

            li {
                padding: 0 20px 0 0;
            }
        }
    }
`;

export const MenuList = styled.ul`
    li {
        font-size: 22px;
        color: transparent;
        transition: .5s ease-in-out;

        @media ${breakpoints.xs} {
            font-size: 25px;
        }

        a {
            color: ${color.beige};
            transition: .5s ease-in-out;

            &:hover {
                // color: ${color.beige};
            }
        }

        &.active {
            // color: ${color.beige};

            a {
                // color: ${color.beige};
            }
        }
    }
`;

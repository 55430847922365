import React from 'react';
import { FooterContainer, FooterContent, ContractAdd, Copyright, Button } from './styles';

const Footer = () => {

  return (
    <FooterContainer >

      <div className='main-container'>
        <ContractAdd>
          <img src='/images/svg/symbol.svg' alt='' />
          <p>Smart contract address: <br className='hide--aboveXs' /><b>0x73AC7eD57fbe0AB4a25B4556550347201f7Ed251</b></p>
        </ContractAdd>

        <FooterContent>
          <Copyright>MohawkNFT. All rights Reserved.</Copyright>
          <Button href='/tnc'>Terms &amp; Conditions</Button>
        </FooterContent>
      </div>

    </FooterContainer>
  );
};

export default Footer;
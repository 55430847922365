import styled from 'styled-components';
import { breakpoints, color } from '../../../theme/index';

export const BuyButton = styled.button`
    background: ${color.beige};
    color: ${color.red};
    cursor: pointer;
    border: 0;
    font-weight: 600;
    margin: 0 auto 70px;
    font-size: 46px;
    text-shadow: -1px 0px 1px ${color.red};
    padding: 20px 35px;
    text-transform: uppercase;

    @media ${breakpoints.md} {
        font-size: 27px;
        margin: 40px auto 0;
    }

    @media ${breakpoints.xs} {
        margin: 40px auto;
        padding: 15px 8px;
        width: 100%;
        font-size: 27px;
    }
`;